import React from 'react'
import { Navigate, Route, useParams } from 'react-router-dom'
import { AuthRoute, FlexRoutes } from 'src/routes'
import ConnectCustomers from 'src/connect/customers'
import ConnectChannels from 'src/connect/channels'
import ConnectSubscriptions from 'src/connect/subscriptions'
import ConnectUsers from 'src/connect/users'

function ConnectRoutes() {
  const { env } = useParams()

  return (
    // relative to /connect/:env/*
    <FlexRoutes>
      <Route
        path="/customers/:connectCustomerId/channels/:connectChannelId/users/*"
        element={<AuthRoute as={ConnectUsers} />}
      />
      <Route
        path="/customers/:connectCustomerId/channels/:connectChannelId/users"
        element={<AuthRoute as={ConnectUsers} />}
      />
      <Route
        path="/customers/:connectCustomerId/channels/:connectChannelId/subscriptions/*"
        element={<AuthRoute as={ConnectSubscriptions} />}
      />
      <Route
        path="/customers/:connectCustomerId/channels/:connectChannelId/subscriptions"
        element={<AuthRoute as={ConnectSubscriptions} />}
      />
      <Route
        path="/customers/:connectCustomerId/channels/*"
        element={<AuthRoute as={ConnectChannels} />}
      />
      <Route path="/customers/*" element={<AuthRoute as={ConnectCustomers} />} />
      <Route
        path="*"
        element={
          <AuthRoute as={() => <Navigate to={`/connect/${env}/customers`} replace />} env={env} />
        }
      />
    </FlexRoutes>
  )
}

export default ConnectRoutes
